<section id="coming" class="">
    <div class="container">
        <div class="coming-main text-center">
            <div class="coming-content mar-bottom-20">
                <img [src]="logo" width="50%" class="mar-bottom-30">
                <h1 class="">Tu sesión ha caducado</h1>
                <!-- <p class="">No se puede encontrar la página que está buscando</p> -->
            </div>

            <div class="error-content text-center">
                <div class="error-box">
                    <div class="error-box-inner">
                        <h1 class="white">403</h1>
                    </div>
                </div>
                <p class="mar-0">No se ha podido realizar esta acción. Vuelve a <a class="pointer" (click)="gotoLogin()" >iniciar sesión</a></p>
            </div>

            <div class="follow_us text-center mar-top-30">
                <ul class="social_icons">
                    <li><a > <i class="fa fa-facebook"></i></a></li>
                    <li><a ><i class="fa fa-twitter"></i></a></li>
                    <li><a ><i class="fa fa-instagram"></i></a></li>
                    <li><a ><i class="fa fa-linkedin"></i></a></li>
                </ul>
            </div>
        </div>

    </div>
</section>