<!-- Header -->
<app-header></app-header>
<!-- Header end -->

<!-- Page -->
<main class="mt-blog">
    <router-outlet></router-outlet>
</main>
<!-- Page end -->

<!-- Footer -->
<app-footer></app-footer>
<!-- Footer end -->