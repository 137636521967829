import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    $(document).ready(function () {
      $(function () {
        $('#tooltip-1').tooltip();
        $('#tooltip-2').tooltip();
        $('#tooltip-3').tooltip();
        $('#tooltip-4').tooltip();
        $('#tooltip-5').tooltip();
        $('#tooltip-6').tooltip();
      });
    });
  }
}
