import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { API_ROUTES } from "@data/constants/routes";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    
    constructor(
        private router: Router,
    ){

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err) => {
                if(
                    [400, 401, 403, 404, 500].indexOf(err.status) !== -1
                    && req.url !== API_ROUTES.AUTH.LOGIN
                    && req.url !== API_ROUTES.AUTH.REGISTER
                    && req.url !== API_ROUTES.AUTH.RESET_PASSWORD
                    && req.url !== API_ROUTES.AUTH.RESTORE_PASSWORD
                ) {
                    // console.log('/'+err.status);
                    this.router.navigateByUrl('/'+err.status);
                }
                console.log(err);
                return throwError(err);
            })
        );
    }

}