import { Component, OnInit } from '@angular/core';
import { IMAGES_ROUTES } from '@data/constants/routes';
import { AuthService } from '@data/services/api/auth.service';
import { environment as ENV } from '@env/environment.dev';

@Component({
  selector: 'app-page403',
  templateUrl: './page403.component.html',
  styleUrls: ['./page403.component.scss']
})
export class Page403Component implements OnInit {

  public logo = ENV.type ? IMAGES_ROUTES.LOGO_MANTA : IMAGES_ROUTES.LOGO_STO_DOMINGO;

  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit(): void {}

  gotoLogin() {
    this.authService.logout();
  }

}
