import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { API_ROUTES } from "@data/constants/routes";
import { AuthService } from "@data/services/api/auth.service";
import { Observable } from "rxjs";

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    
    constructor(
        private authService: AuthService,
    ){

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.getUser;
        const isAuthenticated = currentUser && currentUser.accessToken;
        if (
            isAuthenticated
            && req.url !== API_ROUTES.AUTH.LOGIN
            && req.url !== API_ROUTES.AUTH.REGISTER
            && req.url !== API_ROUTES.AUTH.USER_ME
            && req.url !== API_ROUTES.AUTH.RESET_PASSWORD
            && req.url !== API_ROUTES.AUTH.RESTORE_PASSWORD
            && req.url !== API_ROUTES.BLOG.POST
        ) {
            req = req.clone({
                setHeaders : {
                    Authorization : `Bearer ${currentUser.accessToken}`
                }
            });
        }
        return next.handle(req);
    }

}