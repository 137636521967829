export const environment = {
  production: false,
  type: true,
  url_manta: 'https://oim-server.herokuapp.com/api/v1',
  url_sto_domingo: 'https://oim-server.herokuapp.com/api/v1',
  firebase: {
    apiKey: 'AIzaSyBJTbb4meCfDQG-hVy4tG7Ax6HEbpx0B4M',
    authDomain: 'blog-eiteck.firebaseapp.com',
    projectId: 'blog-eiteck',
    storageBucket: 'blog-eiteck.appspot.com',
    messagingSenderId: '410357744084',
    appId: '1:410357744084:web:459b4ff2018477c0e99641',
    measurementId: 'G-CS0KJDTGG0',
  },
};
