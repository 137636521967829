import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { INTERNAL_PATHS, INTERNAL_ROUTES } from '@data/constants/routes';
import { SkeletonComponent } from '@layout/skeleton/skeleton.component';
import { Page400Component } from '@modules/server/page400/page400.component';
import { Page403Component } from '@modules/server/page403/page403.component';
import { Page404Component } from '@modules/server/page404/page404.component';
import { Page500Component } from '@modules/server/page500/page500.component';

const routes: Routes = [
  { path: '', redirectTo: INTERNAL_ROUTES.BLOG_DEFAULT, pathMatch: 'full' },
  {
    path: INTERNAL_PATHS.SERVER_ERROR_404,
    component: Page404Component,
  },
  {
    path: INTERNAL_PATHS.SERVER_ERROR_400,
    component: Page400Component,
  },
  {
    path: INTERNAL_PATHS.SERVER_ERROR_403,
    component: Page403Component,
  },
  {
    path: INTERNAL_PATHS.SERVER_ERROR_500,
    component: Page500Component,
  },
  {
    path: '',
    component: SkeletonComponent,
    children: [
      {
        path: INTERNAL_PATHS.HOME,
        loadChildren: () =>
          import('@modules/blog/blog.module').then((m) => m.BlogModule),
      },
      {
        path: INTERNAL_PATHS.DEFAULT_NEWS,
        loadChildren: () =>
          import('@modules/news/news.module').then((m) => m.NewsModule),
      },
      {
        path: INTERNAL_PATHS.TRAINING_DEFAULT,
        loadChildren: () =>
          import('@modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: INTERNAL_PATHS.AUTH_DEFAULT,
        loadChildren: () =>
          import('@modules/auth/auth.module').then((m) => m.AuthModule),
      },
      {
        path: '**',
        redirectTo: INTERNAL_ROUTES.BLOG_DEFAULT,
        pathMatch: 'full',
      },
    ],
  },
  { path: '**', redirectTo: INTERNAL_ROUTES.BLOG_DEFAULT, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
