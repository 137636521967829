<!--*Footer*-->
<footer id="mt_footer" class="mt_footer_style1">
  <div class="container">
    <div class="mt_footer_col">
      <div class="row">
        <!-- <div class="col-md-3 col-sm-6 col-xs-12">
                    <div class="mt_footer_about">
                        <h2><a>Suchana</a></h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        </p>
                    </div>
                </div> -->
      </div>
    </div>
    <div class="mt_footer_copy">
      <div class="follow_us text-center mb-4">
        <ul class="social_icons">
          <li>
            <a
              class="footer-item"
              href="https://www.oim.org.ec/2016/iomtemplate2/"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              id="tooltip-5"
            >
              <img src="../../../assets/images/oim-logo.png" width="100"
            /></a>
          </li>
          <li>
            <a
              class="footer-item-pnd"
              href="https://www.ec.undp.org/"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              id="tooltip-5"
            >
              <img
                src="../../../assets/images/pnud_logo.png"
                width="100"
                height="60"
            /></a>
          </li>
          <li>
            <a
              class="footer-suiza"
              href="https://www.eda.admin.ch/deza/es/home/cosude.html"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              id="tooltip-5"
            >
              <img src="../../../assets/images/corp_suiza.png" width="100"
            /></a>
          </li>
          <li>
            <a
              class="footer-item"
              href="https://www.cancilleria.gob.ec/"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              id="tooltip-5"
            >
              <img
                src="../../../assets/images/republica_ecuador.png"
                width="200"
            /></a>
          </li>
          <li>
            <a
              class="footer-item"
              href="https://www.cancilleria.gob.ec/"
              target="_blank"
              data-toggle="tooltip"
              data-placement="top"
              id="tooltip-5"
            >
              <img src="../../../assets/images/mremh-logo.png" width="200"
            /></a>
          </li>

          <li>
            <a
              class="footer-item"
              href="https://www.santodomingo.gob.ec/"
              target="_blank"
            >
              <img
                src="../../../assets/images/sto-domingo-logo.png"
                width="100"
            /></a>
          </li>
          <li>
            <a
              class="footer-item"
              href="https://ccpdsantodomingo.gob.ec/"
              target="_blank"
            >
              <img src="../../../assets/images/ccpd-logo.png" width="100"
            /></a>
          </li>

          <li>
            <a class="footer-item" href="https://manta.gob.ec/" target="_blank">
              <img src="../../../assets/images/manta-logo.png" width="100"
            /></a>
          </li>
        </ul>
      </div>

      <!--  -->
    </div>
  </div>
  <div class="text-center mb-4">
    <h6>
      <a
        class="link"
        target="_blank"
        href="https://migration4development.org/en/about/our-programmes/ecuador"
      >
        Para conocer más sobre el proyecto global conjunto OIM-PNUD ‘El Aporte
        de la Migración al Desarrollo Sostenible’ haga click aquí.</a
      >
    </h6>
    <p class="copy">
      <a href="https://eiteck-page.web.app/home" target="_blank"
        >Copyright &#169; 2022 eiteck</a
      >
    </p>
  </div>
</footer>
<!--* End Footer*-->
