import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carousel-loader',
  templateUrl: './carousel-loader.component.html',
  styleUrls: ['./carousel-loader.component.scss'],
})
export class CarouselLoaderComponent implements OnInit {
  // input styles
  @Input() bars = 1;
  // Final properties
  public totalBars: { width: string }[] = [];
  public finalHeightBar = '0';
  constructor() {}

  ngOnInit(): void {
    for (let i = 0; i < this.bars; i++) {
      const width = 100;
      this.totalBars.push({ width: `${width}%` });
    }
    // bar style
    this.finalHeightBar = `300px`;
  }
}
