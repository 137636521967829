<div class="row">
    <div class="col-xs-12">
        <div class="blog_post_sec">
            <div class="blog-post_wrapper">
                <div class="post-content post-blank">
                    <div class="post-loader__text" [ngStyle]="{'height': finalHeightBar, 'width': item.width}" *ngFor="let item of totalBars"></div>
                </div>
            </div>
        </div>
    </div>
</div>