import { Component, OnInit } from '@angular/core';
import { IMAGES_ROUTES, INTERNAL_ROUTES } from '@data/constants/routes';
import { environment as ENV } from '@env/environment.dev';

@Component({
  selector: 'app-page400',
  templateUrl: './page400.component.html',
  styleUrls: ['./page400.component.scss']
})
export class Page400Component implements OnInit {

  public internalRoutes = INTERNAL_ROUTES;
  public logo = ENV.type ? IMAGES_ROUTES.LOGO_MANTA : IMAGES_ROUTES.LOGO_STO_DOMINGO;

  constructor() { }

  ngOnInit(): void {
  }

}
