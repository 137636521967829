import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ERRORS_CONST } from '@data/constants';
import {
  API_ROUTES,
  INTERNAL_PATHS,
  INTERNAL_ROUTES,
} from '@data/constants/routes';
import { IApiUserAuthenticated } from '@data/interfaces';
import { RoutingService } from '@shared/services/routing.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser: BehaviorSubject<IApiUserAuthenticated>;
  // public userData: Observable<IApiUserAuthenticated>;
  public nameUserLS = 'currentUser';

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private routerExtService: RoutingService
  ) {
    this.currentUser = new BehaviorSubject(
      JSON.parse(localStorage.getItem(this.nameUserLS))
    );
    // this.userData = this.currentUser.asObservable();
  }

  get getUser(): IApiUserAuthenticated {
    return this.currentUser.value;
  }

  /**
   * Login method
   * @param data
   * @returns
   */
  login(data: { email: string; password: string }): Observable<{
    error: boolean;
    msg: string;
    data: any;
  }> {
    const response = { error: true, msg: ERRORS_CONST.LOGIN.ERROR, data: null };
    return this.httpClient
      .post<IApiUserAuthenticated>(API_ROUTES.AUTH.LOGIN, data)
      .pipe(
        map((r) => {
          response.msg = 'Success login';
          response.error = false;
          response.data = r;
          this.setUserToLocalStorage(response.data);
          this.currentUser.next(r);
          if (!response.error) {
            /**
             * Redirect to previous url, except 403 page
             */
            if (this.routerExtService.getPreviousUrl() === '/403') {
              this.router.navigate([INTERNAL_PATHS.HOME]);
            } else {
              this.router.navigate([this.routerExtService.getPreviousUrl()]);
            }
          }
          return response;
        }),
        catchError((e) => {
          // console.log(e);
          response.msg = e.error.message;
          return of(response);
        })
      );
  }

  /**
   * Register method
   * @param data
   * @returns
   */
  register(data: {
    nombre: string;
    lastname: string;
    email: string;
    password: string;
    sex: string;
    lbtiq: boolean;
    age: number;
    countryresidence: string;
    cityresidence: string;
    nationality: string;
    phonenumber: string;
    institutionname: string;
    educationlevel: string;
    groupbelong: string;
    levelresponsibility: string;
    knowledgeworkshop: string;
  }): Observable<{
    error: boolean;
    msg: string;
    data: any;
  }> {
    const response = { error: true, msg: ERRORS_CONST.LOGIN.ERROR, data: null };
    return this.httpClient
      .post<IApiUserAuthenticated>(API_ROUTES.AUTH.REGISTER, data)
      .pipe(
        map((r) => {
          response.msg = 'Success register';
          response.error = false;
          response.data = r;
          this.setUserToLocalStorage(response.data);
          this.currentUser.next(r);
          if (!response.error) {
            /**
             * Redirect to previous url
             */
            this.router.navigate([this.routerExtService.getPreviousUrl()]);
          }
          return response;
        }),
        catchError((e) => {
          // console.log(e);
          response.msg = e.error.message;
          return of(response);
        })
      );
  }

  /**
   * Register method
   * @returns
   */
  userme(): Observable<{
    error: boolean;
    msg: string;
    data: any;
  }> {
    const response = { error: true, msg: ERRORS_CONST.USER_ME, data: null };
    return this.httpClient
      .get<IApiUserAuthenticated>(API_ROUTES.AUTH.USER_ME)
      .pipe(
        map((r) => {
          response.msg = 'Sesión activa';
          response.error = false;
          response.data = r;
          return response;
        }),
        catchError((e) => {
          response.msg = e.error.message;
          return of(response);
        })
      );
  }

  /**
   * Reset password method
   * @param data
   * @returns
   */
  passwordReset(data: { email: string }): Observable<{
    error: boolean;
    msg: string;
    data: any;
  }> {
    const response = { error: true, msg: ERRORS_CONST.LOGIN.ERROR, data: null };
    return this.httpClient
      .post<{ message: string }>(API_ROUTES.AUTH.RESET_PASSWORD, data)
      .pipe(
        map((r) => {
          response.msg = r.message;
          response.error = false;
          response.data = r;
          return response;
        }),
        catchError((e) => {
          response.msg = e.error.message;
          return of(response);
        })
      );
  }

  /**
   * Reset password method
   * @param data
   * @returns
   */
  passwordRestore(data: {
    usercode: string;
    token: string;
    password: string;
  }): Observable<{
    error: boolean;
    msg: string;
    data: any;
  }> {
    const response = { error: true, msg: ERRORS_CONST.LOGIN.ERROR, data: null };
    return this.httpClient
      .post<{ message: string }>(API_ROUTES.AUTH.RESTORE_PASSWORD, data)
      .pipe(
        map((r) => {
          response.msg = r.message;
          response.error = false;
          response.data = r;
          // console.log(r);
          if (!response.error) {
            this.router.navigateByUrl(INTERNAL_ROUTES.AUTH_LOGIN);
          }
          return response;
        }),
        catchError((e) => {
          response.msg = e.error.message;
          return of(response);
        })
      );
  }

  /**
   * Logout method
   */
  logout() {
    localStorage.removeItem(this.nameUserLS);
    this.currentUser.next(null);
    this.router.navigateByUrl(INTERNAL_ROUTES.AUTH_LOGIN);
  }

  private setUserToLocalStorage(user: IApiUserAuthenticated) {
    localStorage.setItem(this.nameUserLS, JSON.stringify(user));
  }
}
