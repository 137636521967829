import { Pipe, PipeTransform } from '@angular/core';
import { environment as ENV } from '@env/environment.dev';

@Pipe({
  name: 'namefromurl'
})
export class NamefromurlPipe implements PipeTransform {

  transform(value: string): any {
    // Santo Domingo pipe
    const url = ENV.type ? ENV.url_manta : ENV.url_sto_domingo.substring(0, (ENV.type ? ENV.url_manta : ENV.url_sto_domingo).length - 7);
    const pre = decodeURI(value.substring(`${url}/uploads/`.length));
    const name = pre.substring(pre.indexOf('-') + 1);
    let aux = name.substring(0, name.length);
    const len = aux.length;
    if (len > 30)
      return aux.substr(0, 20) + '... ' + aux.substring(len - 9, len);
    return aux;
  }
}