import { environment as ENV } from '@env/environment.dev';

const url = ENV.type ? ENV.url_manta : ENV.url_manta;

export const API_ROUTES = {
  AUTH: {
    LOGIN: `${url}/auth/user/signin`,
    REGISTER: `${url}/auth/signup`,
    USER_ME: `${url}/user/me`,
    RESET_PASSWORD: `${url}/auth/password/reset`,
    RESTORE_PASSWORD: `${url}/auth/password/restore`,
  },
  BLOG: {
    POST: `${url}/posts`,
    COMMENTS: `${url}/comments`,
    TOPICS: `${url}/topics`,
    QUIZ: `${url}/quiz`,
    USER_POST: `${url}/posts/user`,
  },
  NEWS: {
    POST: `${url}/notices`,
  },
};
