<div id="mt_banner" class="innerbanner post-loader">
    <div class="container-fluid">
        <div class="post-loader__featured-bg featured-bg"></div>
        <div class="banner-caption">
            <div class="banner_caption_text">
                <div class="post-loader__text" [ngStyle]="{'height': '40px', 'width': '75%'}"></div>
                <br>
                <div class="item-meta">
                    <div class="post-loader__text" [ngStyle]="{'height': finalHeightBar, 'width': item.width}" *ngFor="let item of totalBars"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<section id="blog_main_sec" class="section-inner">
    <div class="container">
        <div class="blog-detail-main">
            <div class="post_body">
                <div class="post-loader__lines" [ngStyle]="{'height': finalHeightBar, 'width': item.width}" *ngFor="let item of totalLines"></div>
            </div>
        </div>
    </div>
</section>