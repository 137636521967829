
<section id="coming" class="">
    <div class="container">
        
        <div class="row">
            <div class="col-xs-12 vcenter">
                <div class="coming-main text-center">
                    <div class="coming-content mar-bottom-20">
                        <img [src]="logo" width="50%" class="mar-bottom-30">
                        <h1 class="">Error del Servidor</h1>
                    </div>
        
                    <div class="error-content text-center">
                        <div class="error-box">
                            <div class="error-box-inner">
                                <h1 class="white">500</h1>
                            </div>
                        </div>
                        <p class="mar-0">Algo salió mal!. Volver al <a [routerLink]="internalRoutes.BLOG_DEFAULT">inicio</a></p>
                    </div>
        
                    <div class="follow_us text-center mar-top-30">
                        <ul class="social_icons">
                            <li><a > <i class="fa fa-facebook"></i></a></li>
                            <li><a ><i class="fa fa-twitter"></i></a></li>
                            <li><a ><i class="fa fa-instagram"></i></a></li>
                            <li><a ><i class="fa fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>