<!-- Header -->
<header id="inner-navigation">
  <!-- <nav class="navbar navbar-default navbar-fixed-top navbar-sticky-function navbar-arrow">
        <div class="container">
            <div class="navbar-nav-wrapper text-center" style="display: contents;">
                <a class="logo" [routerLink]="internalRoutes.BLOG_DEFAULT"><img [src]="logo"></a>
                <ul style="margin-top: 18px;" class="nav navbar-nav navbar-right" id="responsive-menu">
                    <ng-container *ngIf="!authService.getUser; else elseblock">
                        <li [routerLinkActive]="['active']"><a [routerLink]="internalRoutes.AUTH_LOGIN">Iniciar sesión</a></li>
                    </ng-container>
                    <ng-template #elseblock>
                        <li [routerLinkActive]="['active']"><a [routerLink]="internalRoutes.TRAINING_DEFAULT">Capacitaciones</a></li>
                        <li [routerLinkActive]="['active']"><a [routerLink]="internalRoutes.TRAINING_PROFILE">{{authService.getUser.name}}</a></li>
                        <li [routerLinkActive]="['active']"><a [routerLink]="internalRoutes.AUTH_LOGIN">Cerrar sesión</a></li>
                    </ng-template>
                </ul>
            </div>
        </div>
        <div id="slicknav-mobile"></div>
    </nav> -->

  <nav class="navbar navbar-default navbar-fixed-top">
    <div class="container">
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle collapsed"
          (click)="toggleState()"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <!-- <a class="navbar-brand" [routerLink]="internalRoutes.BLOG_DEFAULT"><img [src]="logo"></a> -->
        <!-- <a class="navbar-brand" [routerLink]="internalRoutes.BLOG_DEFAULT">Hola</a> -->
        <a
          class="navbar-brand"
          rel="home"
          [routerLink]="internalRoutes.BLOG_DEFAULT"
        >
          <!--  <h3>D<span>iasporaecuador</span></h3> -->
        </a>
      </div>
      <div class="navbar-collapse" [ngClass]="{ collapse: isCollapse }">
        <ul class="navbar-nav navbar-right" id="responsive-menu">
          <li>
            <a
              (click)="toggleState()"
              [routerLink]="internalRoutes.BLOG_DEFAULT"
              >Inicio</a
            >
          </li>
          <li>
            <a
              (click)="toggleState()"
              [routerLink]="internalRoutes.DEFAULT_BLOG"
              >Blog</a
            >
          </li>
          <li>
            <a
              (click)="toggleState()"
              [routerLink]="internalRoutes.DEFAULT_NEWS"
              >Noticias</a
            >
          </li>
          <ng-container *ngIf="!authService.getUser; else elseblock">
            <li>
              <a [routerLink]="internalRoutes.AUTH_LOGIN">Iniciar sesión</a>
            </li>
          </ng-container>
          <ng-template #elseblock>
            <li>
              <a
                (click)="toggleState()"
                [routerLink]="internalRoutes.TRAINING_DEFAULT"
                >Capacitaciones</a
              >
            </li>

            <li>
              <a
                (click)="toggleState()"
                [routerLink]="internalRoutes.TRAINING_PROFILE"
                >Perfil</a
              >
            </li>
            <li style="cursor: pointer" [routerLinkActive]="['active']">
              <a (click)="logout()">Cerrar sesión</a>
            </li>
          </ng-template>
        </ul>
      </div>
    </div>
  </nav>
</header>
<!-- End Header -->
