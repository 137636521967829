import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shorttext'
})
export class ShorttextPipe implements PipeTransform {

  transform(text: string): any {
    if (text) {
      const len = text.length;
      if (len > 30) // only shorten if greater than 30
        // change value 21 and 9 as per requirement
        return text.substr(0, 21) + '...' + text.substring(len - 9, len);
      return text;
    }
    return text;
  }

}
