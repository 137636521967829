import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-trainings-loader',
  templateUrl: './trainings-loader.component.html',
  styleUrls: ['./trainings-loader.component.scss']
})
export class TrainingsLoaderComponent implements OnInit {

  // input styles
  @Input() bars = 4;

  // Final properties
  public totalBars: {width: string}[] = [];
  public finalHeightBar = '0';

  constructor() { }

  ngOnInit(): void {

    for (let i = 0; i < this.bars; i++) {
      const width = Math.floor(Math.random() * (90 - 20)) + 20;
      this.totalBars.push({width: `${width}%`});
    };

    // bar style
    this.finalHeightBar = `20px`;
  }

}
