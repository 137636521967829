export const ROUTES_PATHS = {
  AUTH: {
    DEFAULT: `auth`,
    LOGIN: `login`,
    REGISTER: `register`,
    FORGOT_PASSWORD: 'forgot_password',
    RESET_PASSWORD: 'reset_password',
    TERMS: 'terms_conditions',
  },
  BLOG: {
    DEFAULT: `home`,
  },
  SERVER: {
    ERROR_404: `404`,
    ERROR_400: `400`,
    ERROR_403: `403`,
    ERROR_500: `500`,
  },
  DEFAULT: {
    HOME: 'home',
    BLOG: `blog`,
    NEWS: `news`,
  },
  USER: {
    DEFAULT: `training`,
    PROFILE: `profile`,
  },
};

export const INTERNAL_PATHS = {
  /**
   * Auth
   */
  AUTH_DEFAULT: `${ROUTES_PATHS.AUTH.DEFAULT}`,
  AUTH_LOGIN: `${ROUTES_PATHS.AUTH.LOGIN}`,
  AUTH_REGISTER: `${ROUTES_PATHS.AUTH.REGISTER}`,
  AUTH_FORGOT_PASSWORD: `${ROUTES_PATHS.AUTH.FORGOT_PASSWORD}`,
  AUTH_RESET_PASSWORD: `${ROUTES_PATHS.AUTH.RESET_PASSWORD}`,
  AUTH_TERMS: `${ROUTES_PATHS.AUTH.TERMS}`,
  /**
   * Blog
   */
  HOME: `${ROUTES_PATHS.BLOG.DEFAULT}`,
  /**
   * User
   */
  TRAINING_DEFAULT: `${ROUTES_PATHS.USER.DEFAULT}`,
  TRAINING_PROFILE: `${ROUTES_PATHS.USER.PROFILE}`,
  /**
   * Server
   */
  SERVER_ERROR_404: `${ROUTES_PATHS.SERVER.ERROR_404}`,
  SERVER_ERROR_400: `${ROUTES_PATHS.SERVER.ERROR_400}`,
  SERVER_ERROR_403: `${ROUTES_PATHS.SERVER.ERROR_403}`,
  SERVER_ERROR_500: `${ROUTES_PATHS.SERVER.ERROR_500}`,

  //DEFAULT
  DEFAULT_HOME: `${ROUTES_PATHS.DEFAULT.HOME}`,
  DEFAULT_BLOG: `home/${ROUTES_PATHS.DEFAULT.BLOG}`,
  DEFAULT_NEWS: `${ROUTES_PATHS.DEFAULT.NEWS}`,
};

export const INTERNAL_ROUTES = {
  /**
   * Auth
   */
  AUTH_LOGIN: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_LOGIN}`,
  AUTH_REGISTER: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_REGISTER}`,
  AUTH_FORGOT_PASSWORD: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_FORGOT_PASSWORD}`,
  AUTH_RESET_PASSWORD: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_RESET_PASSWORD}`,
  AUTH_TERMS: `/${INTERNAL_PATHS.AUTH_DEFAULT}/${INTERNAL_PATHS.AUTH_TERMS}`,
  /**
   * Server
   */
  SERVER_ERROR_404: `/${INTERNAL_PATHS.SERVER_ERROR_404}`,
  SERVER_ERROR_400: `/${INTERNAL_PATHS.SERVER_ERROR_400}`,
  SERVER_ERROR_403: `/${INTERNAL_PATHS.SERVER_ERROR_403}`,
  SERVER_ERROR_500: `/${INTERNAL_PATHS.SERVER_ERROR_500}`,
  /**
   * Blog
   */
  BLOG_DEFAULT: `/${INTERNAL_PATHS.HOME}`,
  /**
   * User
   */
  TRAINING_DEFAULT: `/${INTERNAL_PATHS.TRAINING_DEFAULT}`,
  TRAINING_PROFILE: `/${INTERNAL_PATHS.TRAINING_DEFAULT}/${INTERNAL_PATHS.TRAINING_PROFILE}`,

  //DEFAULT
  DEFAULT_HOME: `/${INTERNAL_PATHS.DEFAULT_HOME}`,
  DEFAULT_BLOG: `/${INTERNAL_PATHS.DEFAULT_BLOG}`,
  DEFAULT_NEWS: `/${INTERNAL_PATHS.DEFAULT_NEWS}`,
};
