import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-detail-loader',
  templateUrl: './post-detail-loader.component.html',
  styleUrls: ['./post-detail-loader.component.scss']
})
export class PostDetailLoaderComponent implements OnInit {

  // input styles
  @Input() lines = 6;

  // Final properties
  public totalBars: {width: string}[] = [];
  public totalLines: {width: string}[] = [];
  public finalHeightBar = '0';

  constructor() { }

  ngOnInit(): void {
    
    this.totalBars.push({width: `60%`});
    this.totalBars.push({width: `25%`});

    for (let i = 0; i < this.lines; i++) {
      const width = Math.floor(Math.random() * (100 - 80)) + 80;
      this.totalLines.push({width: `${width}%`});
    };

    // bar style
    this.finalHeightBar = `15px`;
  }

}
