import { Component, Input, OnInit } from '@angular/core';
import { min } from 'rxjs/operators';

@Component({
  selector: 'app-post-loader',
  templateUrl: './post-loader.component.html',
  styleUrls: ['./post-loader.component.scss']
})
export class PostLoaderComponent implements OnInit {

  // input styles
  @Input() cardHeight = 420;
  @Input() imageHeight = 75;
  @Input() imageWidht = 75;
  @Input() barHeight = 15;
  @Input() bars = 1;

  // Final properties
  public totalBars: {width: string}[] = [];
  public finalStyleImage = {};
  public finalHeightBar = '0';
  public finalCardHeight = '0';

  constructor() { }

  ngOnInit(): void {
    // Calculate total bars
    for (let i = 0; i < this.bars; i++) {
      const width = Math.floor(Math.random() * (100 - 60)) + 60;
      this.totalBars.push({width: `${width}%`});
    };
    // img style
    this.finalStyleImage = {
      width: `100%`,
      height: `${this.imageHeight}px`
    };
    // bar style
    this.finalHeightBar = `${this.barHeight}px`;
    this.finalCardHeight = `${this.cardHeight}px`;
  }

}
