import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IMAGES_ROUTES, INTERNAL_ROUTES } from '@data/constants/routes';
import { AuthService } from '@data/services/api/auth.service';
import { environment as ENV } from '@env/environment.dev';
declare var $: any;
import { HostListener } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  /**
   * Constants routes
   */
  public logo = ENV.type
    ? IMAGES_ROUTES.LOGO_MANTA
    : IMAGES_ROUTES.LOGO_STO_DOMINGO;
  public internalRoutes = INTERNAL_ROUTES;
  public screenWidth: any;
  public screenHeight: any;
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    //console.log(window.innerWidth);
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (window.innerWidth < 600) {
      //this.logo = '../../../assets/images/logo_oim_v3.png';
    }
  }
  ngAfterViewInit() {
    $(document).ready(function () {
      var $slicknav_label;
      $('.responsive-menu').slicknav({
        duration: 500,
        easingOpen: 'easeInExpo',
        easingClose: 'easeOutExpo',
        closedSymbol: '<i class="fa fa-plus"></i>',
        openedSymbol: '<i class="fa fa-minus"></i>',
        prependTo: '#slicknav-mobile',
        allowParentLinks: true,
        label: '',
      });

      var $slicknav_label;
      $('#responsive-menu').slicknav({
        duration: 500,
        easingOpen: 'easeInExpo',
        easingClose: 'easeOutExpo',
        closedSymbol: '<i class="fa fa-plus"></i>',
        openedSymbol: '<i class="fa fa-minus"></i>',
        prependTo: '#slicknav-mobile',
        allowParentLinks: true,
        label: '',
      });

      /**
       * Sticky Header
       */

      $(window).scroll(function () {
        if ($(window).scrollTop() > 10) {
          $('.navbar').addClass('navbar-sticky-in');
        } else {
          $('.navbar').removeClass('navbar-sticky-in');
        }
      });

      /**
       * Main Menu Slide Down Effect
       */

      var selected = $('#navbar li');
      // Mouse-enter dropdown
      selected.on('mouseenter', function () {
        $(this)
          .find('ul')
          .first()
          .stop(true, true)
          .delay(350)
          .slideDown(500, 'easeInOutQuad');
      });

      // Mouse-leave dropdown
      selected.on('mouseleave', function () {
        $(this)
          .find('ul')
          .first()
          .stop(true, true)
          .delay(100)
          .slideUp(150, 'easeInOutQuad');
      });

      /**
       *  Arrow for Menu has sub-menu
       */
      if ($(window).width() > 992) {
        $('.navbar-arrow ul ul > li')
          .has('ul')
          .children('a')
          .append("<i class='arrow-indicator fa fa-angle-right'></i>");
      }
    });
  }

  isCollapse = true;

  toggleState() {
    let foo = this.isCollapse;
    this.isCollapse = foo === false ? true : false;
  }

  logout() {
    this.toggleState();
    this.authService.logout();
  }
}
