import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import * as components from './components';
import * as pipes from './pipes';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CarouselLoaderComponent } from './components/loaders/carousel-loader/carousel-loader.component';
import { MessagesModule } from 'primeng/messages';
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    MessagesModule,
    ToastModule,
    ConfirmDialogModule,
  ],
  declarations: [
    ...components.components,
    ...pipes.pipes,
    CarouselLoaderComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ...components.components,
    ...pipes.pipes,

    ConfirmDialogModule,
  ],
  providers: [ConfirmationService, MessageService],
})
export class SharedModule {}
